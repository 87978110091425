export default [
  {
    question: 'What is urine screening primarily used for?',
    correct: 1,
    answers: [
      'Detection of very recent use of drugs and alcohol',
      'Detection of recent drug use',
      'Detection of impairment',
      'Detection of environmental exposure to drugs'
    ]
  },
  {
    question: 'What must be done before the collection of a urine sample? ',
    correct: 2,
    answers: [
      'The donor should drink a pint of water',
      'The donor should refrain from eating, drinking or smoking',
      'Prepare the collection area',
      'Alert colleagues that drug testing is happening'
    ]
  },
  {
    question: 'Where should we complete the paperwork and discuss results?',
    correct: 1,
    answers: [
      'In a room with everyone else',
      'One to one in a private room',
      'In a room with other donors',
      'With a qualified nurse'
    ]
  },
  {
    question: 'Which 3 crucial bits of information are detailed on a consent form?',
    correct: 0,
    answers: [
      'Identification, medication and signature',
      'Identification, medical history and signature',
      'Place of birth, medical history and surname',
      'Surname, diet and location'
    ]
  },
  {
    question: 'Why should you ask the donor to select the test from the box?',
    correct: 3,
    answers: [
      'To remove accusations of result fixing',
      'To include them in the process',
      'So that the donor can see that the test is within its expiry date',
      'All of the above'
    ]
  },
  {
    question: 'Before you handle the drug test, what should you do?',
    correct: 0,
    answers: [
      'Put on gloves',
      'Wear a protective jacket',
      'Disinfect the surfaces',
      'Move to the sink'
    ]
  },
  {
    question: 'What do we guard against in the preparation of the collection area?',
    correct: 1,
    answers: [
      'Adulteration, Infection and Substitution',
      'Adulteration, Substitution and Dilution',
      'Adulteration, Substitution and Contamination',
      'Adulteration, Substitution and ingestion'
    ]
  },
  {
    question: 'What is the most important thing to check when the donor gives you the collection cup?',
    correct: 1,
    answers: [
      'That the lid is secure',
      'That the sample is the right temperature',
      'That the cup isn\'t damaged',
      'The test results'
    ]
  },
  {
    question: 'If the sample has a blue tinge, why might this be a problem?',
    correct: 1,
    answers: [
      'The donor has been eating something with high anthocyanin content',
      'The donor has dipped the cup into a prepared water source',
      'The cup has picked up a trace of dye and is void',
      'The test is beyond its expiry date'
    ]
  },
  {
    question: 'When you add a sample to the test, how do you know it is running correctly?',
    correct: 0,
    answers: [
      'There is a pink wash that runs up each test strip',
      'The wall of the cup changes colour',
      'There is a pink wash that runs up some of the test strips',
      'The test becomes warm as the chemical reaction takes place'
    ]
  },
  {
    question: 'What can you do if you suspect that the sample has been tampered with during collection? ',
    correct: 2,
    answers: [
      'Treat the sample with a tablet of neutralising agent',
      'Add 5ml of mineral water',
      'Run an adulteration test, and ask for another sample',
      'Proceed with the process, assuming that the donor has something to hide'
    ]
  },
  {
    question: 'What do you do if a test strip has no Test Line (T) but the background still has a pink tint?',
    correct: 1,
    answers: [
      'Write it up as a non-negative',
      'Give the test a little more time to develop',
      'Write the result up as negative',
      'Send the sample for confirmation'
    ]
  },
  {
    question: 'When do we send a sample for lab confirmation',
    correct: 0,
    answers: [
      'When the result is a non-negative',
      'When we think it should be a non-negative',
      'All samples should be sent to the lab',
      'When the screening result is invalid'
    ]
  },
  {
    question: 'Why is chain of custody important?',
    correct: 0,
    answers: [
      'Because you are ensuring the samples can\'t be switched or tampered with ',
      'It is a legal requirement',
      'Because it prolongs the life of the sample',
      'It keeps the samples out of sunlight'
    ]
  },
  {
    question: 'When should the sample be sent to the lab?',
    correct: 2,
    answers: [
      'Within a month',
      'To arrive 7 days after collection',
      'As soon as possible',
      'Before the expiry date of the kit'
    ]
  },
  {
    question: 'What does the confirmation result show?',
    correct: 3,
    answers: [
      'Identifies that an analyte in a non-negative sample was/wasn\'t an illicit substance',
      'Whether a non-negative with declared medication is a negative/non-negative result',
      'That a declaration regarding what was found in the sample is true or false',
      'All of the above'
    ]
  },
  {
    question: 'What happens to the drug test after screening?',
    correct: 1,
    answers: [
      'Donor keeps the test',
      'The test is disposed of',
      'The test is kept as a record',
      'The test is sent to the lab'
    ]
  }
]
